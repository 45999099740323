@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.clients {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .filters {
        display: flex;
        gap: 50px;
        margin-top: 30px;
        .input {
            border: 1px solid black;
        }
        .select-container {
            margin-top: pxToVh(20px, 1080px);
            height: 59px;
            width: 250px;
            border: 1px solid black;
            .show-dropdown-options {
                z-index: 10;
            }
            .select-options {
                margin-top: 23.0555555556vh;
            }
        }
    }
    .pagination {
        width: 30%;
        align-self: center;
        padding: 0;
    }
    .edit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        svg {
            cursor: pointer;
            path {
                fill: black;
            }
        }
    }
    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        button {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            height: 28px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
        }
    }
}
