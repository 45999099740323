@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.tariff-card {
    &.green::before {
        display: flex;
        content: '';
        background: #8adb4a;
        height: pxToVh(16px, 1080px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: #8adb4a;
    }
    &.blue::before {
        display: flex;
        content: '';
        background: #8adb4a;
        height: pxToVh(16px, 1080px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: #1460f3;
    }
    &.yellow::before {
        display: flex;
        content: '';
        background: #8adb4a;
        height: pxToVh(16px, 1080px);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background: #eebd0e;
    }
}
.tariff-card:hover {
    position: relative;
    bottom: pxToVh(20px, 1080px);
}
.tariff-card {
    width: pxToVw(360px, 1920px);
    height: pxToVh(481px, 1080px);
    border-radius: 8px;
    &.green {
        background: #f1fff0;
    }
    &.blue {
        background: #f0faffc7;
    }
    &.yellow {
        background: #fffcf0;
    }
    .line {
        width: pxToVw(256px, 1920px);
        height: pxToVh(1px, 1080px);
        margin: pxToVh(20px, 1080px) 52px;
        background: #f5f5f5;
    }
    .card-title {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: pxToVw(52px, 1920px);
        margin-top: pxToVh(44px, 1080px);
        .card-title_main {
            color: #949696;
            font-family: Cygre;
            @include fluid-type(24px, 28px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &.green {
                color: #8adb4a;
            }
            &.blue {
                color: #1460f3;
            }
            &.yellow {
                color: #eebd0e;
            }
        }
        .card-title_sub {
            width: pxToVw(256px, 1920px);
            height: pxToVh(48px, 1080px);
            color: #949696;
            font-family: Cygre;
            @include fluid-type(11px, 15px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .card-price {
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            span {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
    .list-title {
        color: #0a1614;
        font-family: Cygre;
        @include fluid-type(12px, 16px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: pxToVw(52px, 1920px);
        margin-bottom: pxToVh(16px, 1080px);
    }
    .list-item {
        svg {
            margin-right: pxToVw(8px, 1920px);
        }
        color: #475569;
        font-family: Cygre;
        @include fluid-type(11px, 15px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: pxToVw(52px, 1920px);
        text-align: start;
    }
    .btn {
        margin-left: pxToVw(23px, 1920px);
        margin-top: pxToVh(48px, 1080px);
        display: inline-flex;
        height: pxToVh(41px, 1080px);
        padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px) pxToVh(8px, 1080px)
            pxToVw(119px, 1920px);
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #8adb4a;
        color: White;
        font-family: Cygre;
        @include fluid-type(14px, 18px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        &.green {
            background: #8adb4a;
        }
        &.blue {
            background: #1460f3;
        }
        &.yellow {
            background: #eebd0e;
        }
    }
}
