@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.help-logo {
    z-index: 10;
    position: absolute;
    bottom: pxToVh(75px, 1080px);
    right: pxToVw(75px, 1920px);
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #9be163;
    cursor: pointer;
}
.support-modal {
    z-index: 2;
    position: absolute;
    bottom: pxToVh(61px, 1080px);
    right: pxToVw(61px, 1920px);
    display: flex;
    flex-direction: column;
    width: pxToVw(437px, 1920px);
    height: pxToVh(240px, 1080px);
    border-radius: 3px 3px 32px 3px;
    background: #8adb4a;
    box-shadow: 0px 0px 6px 0px rgba(155, 225, 99, 0.79);
    gap: 20px;
    .modal-logo {
        position: absolute;
        bottom: pxToVh(14px, 1080px);
        right: pxToVw(14px, 1920px);
        cursor: pointer;
    }
    .support-title {
        color: #fcfffc;
        font-family: Cygre;
        @include fluid-type(20px, 24px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-left: pxToVw(33px, 1920px);
        margin-top: pxToVh(17px, 1080px);
    }
    .support-item {
        display: flex;
        gap: 30px;
        margin-left: pxToVw(33px, 1920px);
        span {
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            line-height: normal;
        }
        .working-mode {
            display: flex;
            flex-direction: column;
            span:first-child {
                color: #fcfffc;
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                line-height: normal;
            }
            span {
                color: #fcfffc;
                font-family: Cygre;
                @include fluid-type(7px, 11px);
                font-style: normal;
                line-height: normal;
            }
        }
    }
}
