@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.MuiModal-root {
    th {
        text-align: start;
    }
    tr{
        margin-bottom: 20px;
    }
}
.click_log {
    .filters {
        margin-top: 40px;
        display: flex;
        gap: 20px;
        .find {
            margin-top: pxToVh(20px, 1080px);
            margin-bottom: pxToVh(34px, 1080px);
        }
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
            .MuiOutlinedInput-notchedOutline {
            border-color: black;
            border-width: 1px;
        }
        .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
            border-radius: 8px;
            height: 59px;
            fieldset {
                border-color: black;
            }
        }
        .reset {
            align-self: center;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            height: 28px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
        }
    }
}
