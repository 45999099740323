@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.pagination {
    display: flex;
    width: pxToVw(1491px, 1920px);
    height: pxToVh(82px, 1080px);
    padding: pxToVh(28px, 1080px) pxToVw(672px, 1920px);
    justify-content: center;
    align-items: center;
    gap: 14px;
    border-radius: 8px;
    background: #fcfffc;
    margin-top: pxToVh(20px, 1080px);

    .pagination-button {
        display: flex;
        width: pxToVw(26px, 1920px);
        height: pxToVh(26px, 1080px);
        padding-bottom: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        &.selected {
            background: #ecffdd;
            color: #8adb4a;
        }
    }
}
.table {
    overflow-x: auto;
    background: #fcfffc;
    font-family: Cygre;
    padding: 0 80px 0 20px;
    width: 100%;
    border-radius: 8px;

    .sort {
        position: relative;
        top: pxToVh(3px, 1080px);
        cursor: pointer;
    }
    .actions-btn {
        position: relative;
        .act-btn {
            margin-right: pxToVw(40px, 1920px);
            cursor: pointer;
        }
        .btn-modal {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: pxToVw(34px, 1920px);
            top: pxToVh(-6px, 1080px);
            width: pxToVw(232px, 1920px);
            border-radius: 3px;
            background: #fcfffc;
            box-shadow: 1px 1px 6px 0px rgba(211, 211, 211, 0.33);
            z-index: 1;
            .act-svg {
                position: absolute;
                top: pxToVh(6px, 1080px);
                right: pxToVw(6px, 1920px);
                cursor: pointer;
            }
            .btn {
                margin-left: pxToVw(25px, 1920px);
                margin-right: pxToVw(25px, 1920px);
                margin-top: pxToVh(25px, 1080px);
                margin-bottom: pxToVh(25px, 1080px);
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: #949696;
                display: inline-flex;
                padding: pxToVh(4px, 1080px) pxToVw(87px, 1920px) 4px
                    pxToVw(8px, 1920px);
                align-items: center;
                background: #fcfffc;
                border: none;
                cursor: pointer;
            }
            .btn:hover {
                color: #0a1614;
                background: #f5f5f5;
                border-radius: 3px;
            }
        }
    }

    table {
        border-collapse: collapse;
    }
    thead {
        th {
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            line-height: normal;
            cursor: pointer;
            .SortIcon {
                position: relative;
                top: pxToVh(3px, 1080px);
            }
        }
    }
    th,
    td {
        text-align: start;
        white-space: nowrap;
        padding: 0.5rem;
        height: pxToVh(44px, 1080px) pxToVw(44px, 1920px) pxToVh(44px, 1080px)
            pxToVw(1491px, 1920px);
    }

    td:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    tbody {
        tr:nth-child(2n) {
            background: #fbfbfb;
            height: pxToVh(44px, 1080px);
        }
        tr:nth-child(2n + 1) {
            height: pxToVh(44px, 1080px);
        }
    }
}
