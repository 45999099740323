@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.balance-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: pxToVw(589px, 1920px);
    height: pxToVh(416px, 1080px);
    border-radius: 8px;
    background: #fcfffc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    .title {
        width: pxToVw(469px, 1920px);
        color: #0a1614;
        font-family: Cygre;
        @include fluid-type(20px, 28px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 pxToVw(60px, 1920px);
        margin-top: pxToVh(40px, 1080px);
        padding-right: pxToVw(100px, 1920px);
        margin-bottom: pxToVh(40px, 1080px);
        &.edit {
            padding-right: 0;
        }
    }
    .sub-title {
        width: pxToVw(469px, 1920px);
        color: #949696;
        font-family: Cygre;
        @include fluid-type(11px, 15px);
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0 pxToVw(60px, 1920px);
        margin-bottom: pxToVh(48px, 1080px);
    }
    .close {
        position: absolute;
        top: pxToVh(20px, 1080px);
        right: pxToVw(20px, 1920px);
        cursor: pointer;
    }
    .input {
        width: pxToVw(469px, 1920px);
        height: pxToVh(61px, 1080px);
        background: #fcfffc;
        border-bottom: 1px solid #b9b9b9;
        border-radius: 0;
        &.error {
            border: none;
            border-bottom: 1px solid red;
        }
    }
    .errorText {
        position: absolute;
        bottom: pxToVh(-10px, 1080px);
        left: pxToVw(20px, 1920px);
        color: red;
        font-family: Cygre;
    }
    .btn-wrapper {
        margin: 0 pxToVw(60px, 1920px);
        margin-top: pxToVh(20px, 1080px);
        display: flex;
        gap: 20px;
        &.edit {
            margin-top: 0;
        }
        .accept {
            display: flex;
            width: pxToVw(224px, 1920px);
            height: pxToVh(41px, 1080px);
            padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px)
                pxToVh(8px, 1080px) pxToVw(119px, 1920px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            background: #8adb4a;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            cursor: pointer;
        }
        .cancel {
            display: flex;
            width: pxToVw(224px, 1920px);
            height: pxToVh(41px, 1080px);
            padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px)
                pxToVh(8px, 1080px) pxToVw(119px, 1920px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            background: #f5f5f5;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            cursor: pointer;
        }
    }
}
.choice-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: pxToVw(589px, 1920px);
    height: pxToVh(416px, 1080px);
    border-radius: 8px;
    background: #fcfffc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    .title {
        width: pxToVw(469px, 1920px);
        color: #0a1614;
        font-family: Cygre;
        @include fluid-type(20px, 28px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 pxToVw(60px, 1920px);
        margin-top: pxToVh(40px, 1080px);
        padding-right: pxToVw(100px, 1920px);
        margin-bottom: pxToVh(40px, 1080px);
        &.edit {
            padding-right: 0;
        }
    }
    .sub-title {
        width: pxToVw(469px, 1920px);
        color: #0a1614;
        font-family: Cygre;
        @include fluid-type(16px, 28px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 pxToVw(60px, 1920px);
        margin-top: pxToVh(10px, 1080px);
        padding-right: pxToVw(100px, 1920px);
        margin-bottom: pxToVh(20px, 1080px);
    }
    .close {
        position: absolute;
        top: pxToVh(20px, 1080px);
        right: pxToVw(20px, 1920px);
        cursor: pointer;
    }
    .input {
        width: pxToVw(469px, 1920px);
        height: pxToVh(61px, 1080px);
        background: #fcfffc;
        border-bottom: 1px solid #b9b9b9;
        border-radius: 0;
        &.error {
            border: none;
            border-bottom: 1px solid red;
        }
    }
    .errorText {
        position: absolute;
        bottom: pxToVh(-10px, 1080px);
        left: pxToVw(20px, 1920px);
        color: red;
        font-family: Cygre;
    }
    .btn-wrapper {
        margin: 0 pxToVw(60px, 1920px);
        margin-bottom: pxToVh(60px, 1080px);
        display: flex;
        gap: 20px;
        &.edit {
            margin-top: 0;
        }
        .accept {
            display: flex;
            width: pxToVw(224px, 1920px);
            height: pxToVh(41px, 1080px);
            padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px)
                pxToVh(8px, 1080px) pxToVw(119px, 1920px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            background: #8adb4a;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            cursor: pointer;
        }
        .cancel {
            display: flex;
            width: pxToVw(224px, 1920px);
            height: pxToVh(41px, 1080px);
            padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px)
                pxToVh(8px, 1080px) pxToVw(119px, 1920px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            background: #f5f5f5;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            border: none;
            cursor: pointer;
        }
    }
}
