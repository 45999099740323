@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.select-container {
    display: inline-block;
    position: relative;
    display: flex;
    width: pxToVw(602px, 1920px);
    height: pxToVh(86px, 1080px);
    padding: pxToVh(30px, 1080px) 20px;
    align-items: center;
    gap: 60px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #f0f0f0;
    &.error {
        border: 1px solid red;
    }
}
.selected-text {
    width: pxToVw(602px, 1920px);
    padding: pxToVh(10px, 1080px) pxToVw(20px, 1920px);
    cursor: pointer;
    .arrow {
        position: absolute;
        right: pxToVw(20px, 1920px);
        transform: rotate(0deg);
        &.active {
            transform: rotate(180deg);
        }
    }
    .placeholder {
        color: #7e8494;
        font-family: Cygre;
        @include fluid-type(14px, 18px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

ul {
    margin: 0;
    padding: 0;
    text-align: center;
    border-radius: 5px;
    z-index: 1;
}

.select-options {
    position: absolute;
    margin-top: pxToVh(195px, 1080px);
    left: 0;
    width: 100%;
}

.select-option {
    background: #f5f5f5;
    height: pxToVh(50px, 1080px);
    list-style-type: none;
    padding: pxToVh(15px, 1080px) 20px;
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
}

.select-option:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.select-option:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.select-option:hover {
    background-color: #d9d9d9;
}

.show-dropdown-options {
    min-height: pxToVh(50px, 1080px);
    opacity: 1;
    visibility: visible;
}

.hide-dropdown-options {
    min-height: 0;
    opacity: 0;
    visibility: hidden;
}
