@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.signIn {
    width: 100vw;
    height: 100vh;
    background: #fcfffc;
    display: flex;

    .greetings {
        background: #eee;
        width: 45vw;
        .logo {
            margin-left: pxToVw(60px, 1920px);
            margin-top: pxToVh(93px, 1080px);
            margin-bottom: pxToVh(70px, 1080px);
        }

        .screen {
            margin-left: pxToVw(60px, 1920px);
            img {
                width: 100%;
                height: auto;
            }
        }

        .title {
            margin-left: pxToVw(60px, 1920px);
            margin-bottom: pxToVh(78px, 1080px);
            .title-mainText {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(36px, 40px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .title-subText {
                width: pxToVw(651px, 1920px);
                color: #9da2a1;
                font-family: Cygre;
                @include fluid-type(22px, 26px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    .form-wrapper {
        background: #fff;
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 55vw;
        .homeIcon {
            margin-right: pxToVw(140px, 1920px);
            margin-top: pxToVh(60px, 1080px);
            margin-bottom: pxToVh(35px, 1080px);
            align-self: flex-end;
            cursor: pointer;
        }

        .title {
            margin-left: pxToVw(60px, 1920px);
            margin-bottom: pxToVh(48px, 1080px);
            .title-mainText {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(36px, 40px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .title-subText {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                a {
                    text-decoration: none;
                    color: #9be163;
                }
            }
        }
        .error-text{
            margin-left: pxToVw(60px, 1920px);
            color: red;
        }
        .form {
            display: flex;
            flex-direction: column;
            margin-left: pxToVw(60px, 1920px);
            .input {
                width: pxToVw(602px, 1920px);
            }
            .action-buttons {
                display: flex;
                width: pxToVw(602px, 1920px);

                .CheckBox {
                    width: 100%;
                }
                a {
                    text-decoration: none;
                    color: #9be163;
                    font-family: Cygre;
                    @include fluid-type(14px, 18px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    position: relative;
                    top: pxToVh(3px, 1080px);
                    white-space: nowrap;
                }
            }
            .formBtn {
                cursor: pointer;
                display: flex;
                width: pxToVw(602px, 1920px);
                padding: pxToVh(24px, 1080px) pxToVw(207px, 1920px);
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #6c7372;
                color: #fcfffc;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
                &.active{
                    background: #9be163;
                }
            }
        }
    }
}
