@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.shop {
    display: flex;
    flex-wrap: wrap;
    margin-top: pxToVh(48px, 1080px);
    .add-shop {
        display: flex;
        flex-direction: column;
        .title {
            .title-text_main {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(46px, 50px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .title-text_sub {
                color: #949696;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: pxToVw(668px, 1920px);
            }
        }
        .linkBtn {
            cursor: pointer;
            white-space: nowrap;
            margin-top: pxToVh(48px, 1080px);
            display: flex;
            width: pxToVw(610px, 1920px);
            height: pxToVh(82px, 1080px);
            padding: pxToVh(24px, 1080px) 207px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #8adb4a;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(16px, 20px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border: none;
        }
    }
    .shop-settings {
        margin-right: pxToVw(20px, 1920px);
        .title-icon {
            cursor: pointer;
        }
        .errorText {
            bottom: -22.4px;
        }
        .profile {
            background: #fcfffc;
            border-radius: 8px;
            width: pxToVw(610px, 1920px);
            height: pxToVh(440px, 1080px);
            flex-shrink: 0;
            margin-bottom: pxToVh(20px, 1080px);
            padding-top: pxToVh(20px, 1080px);
            padding-left: pxToVw(20px, 1920px);
            .profile-title {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 28px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: pxToVh(20px, 1080px);
                margin-bottom: pxToVh(32px, 1080px);
            }
            .MuiFormControlLabel-label {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(18px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .TextField {
                margin-bottom: pxToVh(32px, 1080px);
                margin-top: pxToVh(32px, 1080px);
                width: pxToVw(340px, 1920px);
                height: pxToVh(60px, 1080px);
                .input {
                    width: pxToVw(340px, 1920px);
                    height: pxToVh(60px, 1080px);
                }
            }
            .filter {
                width: pxToVw(105px, 1920px);
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-bottom: pxToVh(10px, 1080px);
                border-bottom: 1px solid #b9b9b9;
                cursor: pointer;
                &.active {
                    color: #0a1614;
                    border-bottom: 1px solid #0a1614;
                }
            }
        }
        .settings {
            background: #fcfffc;
            border-radius: 8px;
            width: pxToVw(610px, 1920px);
            height: pxToVh(343px, 1080px);
            padding: pxToVh(20px, 1080px) pxToVh(20px, 1920px)
                pxToVh(20px, 1080px) pxToVw(20px, 1920px);

            .modal {
                right: -235px;
                top: -124px;
                position: absolute;
                z-index: 10;
            }

            .act {
                rect {
                    fill: #8adb4a;
                }
                path {
                    fill: white;
                }
            }

            .settings-title {
                display: flex;
                justify-content: space-between;
                position: relative;
                .title-text {
                    color: #0a1614;
                    font-family: Cygre;
                    @include fluid-type(20px, 28px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: pxToVh(15px, 1080px);
                }
            }
            .switch-wrapper {
                display: flex;
                flex-direction: column;
                gap: pxToVh(10px, 1080px);
                .MuiFormControlLabel-label {
                    color: #0a1614;
                    font-family: Cygre;
                    @include fluid-type(18px, 24px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }
    }
    .geo {
        height: pxToVh(803px, 1080px);
        width: pxToVw(862px, 1920px);
        background: #fcfffc;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        ::-webkit-scrollbar {
            width: 7px;
        }
        .delete{
            width: 22px;
            height: 22px;
            position: absolute;
            right: 5px;
            cursor: pointer;
        }
        .geo-item{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .list-wrapper{
            height: 400px;
            width: pxToVw(862px, 1920px);
            display: flex;
            overflow-y: auto;
            padding: 40px;
        }
        .list {
            height: 200px;
            width: pxToVw(862px, 1920px);
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 20px;
            overflow-y: auto;
        }

        ::-webkit-scrollbar-track {
            background-color: #f0f1f3;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #dcdcdc;
        }
        .title {
            display: flex;
            margin-left: pxToVw(20px, 1920px);
            margin-top: pxToVh(21px, 1080px);
            .title-text {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 28px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-right: pxToVw(124px, 1920px);
            }
            .add-btn {
                display: flex;
                width: pxToVw(173px, 1920px);
                height: pxToVh(41px, 1080px);
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 3px;
                background: #8adb4a;
                color: #fcfffc;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-right: pxToVw(20px, 1920px);
                border: none;
                cursor: pointer;
            }
            .cancel {
                display: flex;
                width: pxToVw(173px, 1920px);
                height: pxToVh(41px, 1080px);
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 3px;
                border: 1px solid #8adb4a;
                color: #8adb4a;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-right: pxToVw(20px, 1920px);
                cursor: pointer;
            }
        }
        .form-title {
            margin-left: pxToVw(20px, 1920px);
            color: #949696;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .TextField {
            margin-left: pxToVw(20px, 1920px);
            margin-top: pxToVh(20px, 1080px);
        }
        .check-tree {
            margin-left: pxToVw(20px, 1920px);
            margin-top: pxToVh(32px, 1080px);
            height: pxToVh(470px, 1080px);
            display: flex;
            flex-direction: column;
            gap: pxToVh(32px, 1080px);
            .check-title {
                width: 100%;
                margin-bottom: pxToVh(20px, 1080px);
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .tree-item {
                display: flex;
                flex-wrap: wrap;
                gap: pxToVh(20px, 1080px);
            }
        }
    }
    .dop {
        display: flex;
        justify-content: space-between;
    }
    .filters {
        display: flex;
        gap: 20px;
        margin-bottom: pxToVh(24px, 1080px);
        .filter {
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: pxToVh(10px, 1080px);
            border-bottom: 1px solid #b9b9b9;
            cursor: pointer;
            &.active {
                color: #0a1614;
                border-bottom: 1px solid #0a1614;
            }
        }
    }
    .btn-wrapper {
        width: pxToVw(1492px, 1920px);
        display: flex;
        justify-content: center;
        margin-top: pxToVh(40px, 1080px);
        .save-btn {
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: inline-flex;
            padding: pxToVh(4px, 1080px) pxToVw(119px, 1920px)
                pxToVh(8px, 1080px) pxToVw(119px, 1920px);
            height: pxToVh(41px, 1080px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 3px;
            background: #8adb4a;
            border: none;
            cursor: pointer;
        }
    }
}
