@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.balance {
    margin-top: pxToVh(20px, 1080px);
    .card-list {
        margin-bottom: pxToVh(20px, 1080px);
        display: flex;
        gap: 23px;
        .card {
            position: relative;
            width: pxToVw(398px, 1920px);
            height: pxToVh(152px, 1080px);
            border-radius: 8px;
            background: #fcfffc;
            padding-top: pxToVh(24px, 1080px);
            padding-left: pxToVw(24px, 1920px);
            .svg{
                position: absolute;
                top: pxToVh(20px, 1080px);
                right: pxToVw(20px, 1920px);
                cursor: pointer;
            }
            &.pay{
                display: flex;
            }
            .title {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: pxToVh(20px, 1080px);
            }
            .title-value {
                color: #0a1614;
                color: var(--Black, #0a1614);
                font-family: Cygre;
                @include fluid-type(40px, 44px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
    .filters {
        display: flex;
        gap: 20px;
        margin-bottom: pxToVh(24px, 1080px);
        .filter {
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: pxToVh(10px, 1080px);
            border-bottom: 1px solid #b9b9b9;
            cursor: pointer;
            &.active {
                color: #0a1614;
                border-bottom: 1px solid #0a1614;
            }
        }
    }
}
