@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.analytics {
    margin-top: pxToVh(160px, 1080px);
    display: flex;
    flex-direction: column;
    font-family: Cygre;
    .filters_wrapper {
        display: flex;
        width: 77vw;
        align-items: baseline;
        justify-content: space-between;
        .date_wrapper {
            display: flex;
            position: relative;
            .react-datepicker-popper[data-placement^='bottom'] {
                padding-top: 45px;
            }
            .range_picker .react-datepicker__input-container input {
                padding: 8px 14px 8px 25px;
                margin: 0;
                border: none;
                background: #fcfffc;
                border-radius: 3px;
            }
            svg {
                position: absolute;
                left: 5px;
                top: 7px;
                z-index: 1;
            }
        }
        .filters {
            display: flex;
            gap: 20px;
            margin-bottom: pxToVh(24px, 1080px);
            .filter {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding-bottom: pxToVh(10px, 1080px);
                border-bottom: 1px solid #b9b9b9;
                cursor: pointer;
                &.active {
                    color: #0a1614;
                    border-bottom: 1px solid #0a1614;
                }
            }
        }
    }
    .title {
        .title-text_main {
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(46px, 50px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .title-text_sub {
            color: #949696;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: pxToVw(668px, 1920px);
        }
    }
    .linkBtn {
        cursor: pointer;
        white-space: nowrap;
        margin-top: pxToVh(48px, 1080px);
        display: flex;
        width: pxToVw(610px, 1920px);
        height: pxToVh(82px, 1080px);
        padding: pxToVh(24px, 1080px) 207px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #8adb4a;
        color: #fcfffc;
        font-family: Cygre;
        @include fluid-type(16px, 20px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
    }
    .charts {
        display: flex;
        gap: 20px;
        .circle {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: pxToVw(534px, 1920px);
            height: pxToVh(623px, 1080px);
            border-radius: 8px;
            background: #fcfffc;
            .spend{
                width: 100px;
                height: 100px;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
            }
            h6 {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                position: relative;
                bottom: pxToVh(-20px, 1080px);
                width: 100%;
                padding-left: pxToVw(25px, 1920px);
            }
            svg{
                outline: none;
                g{
                    outline: none;
                    stroke: none;
                }
            }
            path{
                outline: none;
            }
            // .recharts-wrapper {
            //     width: pxToVw(850px, 1920px) !important;
            //     height: pxToVh(500px, 1080px) !important;
            //     .recharts-surface {
            //         width: pxToVw(980px, 1920px) !important;
            //         height: pxToVh(500px, 1080px) !important;
            //         text {
            //             color: #0a1614;
            //             @include fluid-type(40px, 44px);
            //             font-style: normal;
            //             font-weight: 600;
            //             line-height: normal;
            //         }
            //         .mid {
            //             @include fluid-type(51px, 55px);
            //         }
            //     }
            // }
        }
        .line {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: pxToVw(928px, 1920px);
            height: pxToVh(623px, 1080px);
            border-radius: 8px;
            background: #fcfffc;
            .filters {
                margin-top: pxToVh(24px, 1080px);
                margin-left: pxToVw(50px, 1920px);
                display: flex;
                gap: 20px;
                margin-bottom: pxToVh(24px, 1080px);
                .filter {
                    color: #b9b9b9;
                    font-family: Cygre;
                    @include fluid-type(12px, 16px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-bottom: pxToVh(10px, 1080px);
                    cursor: pointer;
                    &.active {
                        color: #0a1614;
                    }
                }
            }
        }
    }
}
