@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.layout {
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
    display: grid;
    grid-template-rows: pxToVh(114px, 1080px) auto;
    grid-template-columns: pxToVw(320px, 1920px) auto;
    grid-template-areas:
        'A  B'
        'A  C';
    .header {
        grid-area: B;
    }
    .menu {
        grid-area: A;
    }
    .content {
        grid-area: C;
        width: 100%;
        height: 100%;
        margin-left: pxToVw(48px, 1920px);
    }
}
