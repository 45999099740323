.clients_actions {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .filters {
        display: flex;
        gap: 50px;
        margin-top: 30px;
        .input {
            border: 1px solid black;
        }
    }
    .product {
        cursor: pointer;
    }
    .pagination {
        width: 30%;
        align-self: center;
        padding: 0;
    }
    td:has(.action) {
        padding-top: 17px;
    }
    .action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        button {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            height: 28px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
        }
    }
}
.css-s0w8ce {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    .input {
        margin-top: 20px;
        width: 450px;
    }
    .select-container {
        width: 450px;
    }
    .TextField:last-child {
        position: relative;
        bottom: 35px;
    }
    .buttonGroup {
        display: flex;
        flex-direction: row;
        align-self: center;
        gap: 10px;

        .resetBtn {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #ffffff;
            width: 128px;
            height: 32px;
            background: #42454c;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            opacity: 70%;
        }

        .confirmBtn {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #ffffff;
            width: 128px;
            height: 32px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
            button {
                cursor: pointer;
                color: #ffffff;
                background: #8adb4a;
                border: none;
            }
        }
    }
}
