@font-face {
    font-family: 'Cygre';
    src:
        local('fonts'),
        url('./assets//fonts/Cygre-Regular.ttf') format('truetype');
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font-family: Cygre;
}

body {
    color: #42454c;
}

ul {
    padding: 0;
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

li {
    list-style: none;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}
#root {
    min-height: 100vh;
    overflow: hidden;
}
