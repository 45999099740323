@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.admin_menu {
    width: pxToVw(280px, 1920px);
    height: 100vh;
    position: relative;
    left: 0;
    transition: left 0.5s ease-in-out;
    background: #0a1614;
    .logo_wrapper {
        display: flex;
        margin-left: pxToVw(30px, 1920px);
        margin-top: pxToVh(73px, 1080px);
        margin-bottom: pxToVh(80px, 1080px);
        span {
            color: white;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .link-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToVh(40px, 1080px);
        height: 50%;
        margin-left: pxToVw(30px, 1920px);
        a {
            display: inline-flex;
            text-decoration: none;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .link-logo {
                fill: #dcdcdc;
                width: pxToVw(24px, 1920px);
                height: pxToVh(24px, 1080px);
                margin-right: pxToVw(14px, 1920px);
                color: #b9b9b9;
            }
            &.active {
                color: #8adb4a;
                .link-logo {
                    path {
                        fill: #8adb4a;
                    }
                }
            }
        }
    }
    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: pxToVh(100px, 1080px);
        margin-left: pxToVw(30px, 1920px);
        gap: pxToVh(40px, 1080px);
        .info-text {
            display: flex;
            flex-direction: column;
            gap: pxToVh(20px, 1080px);
            .info-text_main {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .info-text_sub {
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(12px, 16px);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                opacity: 70%;
            }
        }
        .logOut {
            display: inline-flex;
            text-decoration: none;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
            position: relative;
            right: 4px;
            .logo-logOut {
                width: pxToVw(26px, 1920px);
                height: pxToVh(26px, 1080px);
                margin-right: pxToVw(14px, 1920px);
            }
        }
    }
}
