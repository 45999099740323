@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.admin_layout {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: auto;
    background: #f5f5f5;
    display: grid;
    grid-template-rows: pxToVh(114px, 1080px) auto;
    grid-template-columns: pxToVw(320px, 1920px) auto;
    grid-template-areas:
        'A  C'
        'A  C';
    .menu {
        grid-area: A;
    }
    .error-text{
        color: red;
    }
    .content {
        grid-area: C;
        width: 100%;
        overflow-x: auto;
        height: 100%;
        padding: 0 30px;
        padding-bottom: 30px;
    }
}
