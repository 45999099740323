@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.header {
    width: pxToVw(1492px, 1920px);
    height: pxToVh(100px, 1080px);
    border-radius: 8px;
    background: #fcfffc;
    margin-left: pxToVw(48px, 1920px);
    margin-top: pxToVh(14px, 1080px);
    display: flex;
    .greeting {
        color: #8adb4a;
        font-family: Cygre;
        @include fluid-type(20px, 28px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: pxToVh(28px, 1080px) pxToVw(443px, 1920px) pxToVh(27px, 1080px)
            pxToVw(48px, 1920px);
    }
    .balance {
        display: flex;
        gap: 20px;
        align-items: center;
        margin: pxToVh(16px, 1080px) pxToVw(20px, 1920px) pxToVh(15px, 1080px) 0;
        .balance-text {
            display: flex;
            flex-direction: column;
            .balance-text_main {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .balance-text_sub {
                color: #949696;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                cursor: pointer;
            }
        }
    }
    .info {
        display: flex;
        gap: 20px;
        align-items: center;
        margin: pxToVh(16px, 1080px) 0 pxToVh(15px, 1080px) pxToVw(20px, 1920px);
        .info-text {
            display: flex;
            flex-direction: column;
            .info-text_main {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .info-text_sub {
                color: #949696;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
