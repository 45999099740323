@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.startPage {
    width: 100vw;
    height: 100vh;
    background-color: #f9f9f9;
    opacity: 1;
    background-image: radial-gradient(
        #0000001a 1.5500000000000002px,
        #fcfffc 1.5500000000000002px
    );
    background-size: 36px 36px;

    .logo {
        margin-left: pxToVw(108px, 1920px);
        margin-top: pxToVh(93px, 1080px);
        margin-bottom: pxToVh(120px, 1080px);
    }

    .screen-one {
        position: absolute;
        width: pxToVw(940px, 1920px);
        right: pxToVw(0px, 1920px);
        top: pxToVh(65px, 1080px);
        border-radius: 3px;
        img {
            width: 100%;
            height: auto;
        }
    }
    .screen-two {
        z-index: 1;
        position: absolute;
        width: pxToVw(940px, 1920px);
        right: pxToVw(55px, 1920px);
        bottom: pxToVh(118px, 1080px);
        border-radius: 3px;
        img {
            width: 100%;
            height: auto;
        }
    }

    .title {
        margin-left: pxToVw(108px, 1920px);
        margin-bottom: pxToVh(60px, 1080px);
        .title-mainText {
            color: var(--black, #0a1614);
            font-family: Cygre;
            @include fluid-type(36px, 40px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .title-subText {
            width: 651px;
            color: #9da2a1;
            font-family: Cygre;
            @include fluid-type(22px, 26px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-left: pxToVw(108px, 1920px);

        .signInBtn {
            width: pxToVw(602px, 1920px);
            height: pxToVh(83px, 1080px);
            padding: pxToVh(24px, 1080px) 207px;
            text-align: center;
            border-radius: 8px;
            background: var(--green, #9be163);
            color: var(--main-colors-main-white, #fafafa);
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
        }
        .signUpBtn {
            width: pxToVw(602px, 1920px);
            color: var(--green, #9be163);
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
            text-align: center;
        }
    }
}
