@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.logOutModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: pxToVw(797px, 1920px);
    height: pxToVh(373px, 1080px);
    border-radius: 8px;
    background: #8adb4a;
    border: none;
    outline: none;
    .modal-title {
        color: #fcfffc;
        font-family: Cygre;
        @include fluid-type(40px, 44px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        margin-top: pxToVh(74px, 1080px);
        margin-bottom: pxToVh(70px, 1080px);
    }
    .btn-group {
        margin-left: pxToVw(96px, 1920px);
        display: flex;
        gap: 14px;
        .logOut-btn {
            border: none;
            display: flex;
            width: pxToVw(296px, 1920px);
            height: pxToVh(82px, 1080px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #fcfffc;
            color: #8adb4a;
            font-family: Cygre;
            @include fluid-type(16px, 20px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
        }
        .close-btn {
            background: transparent;
            display: flex;
            width: pxToVw(296px, 1920px);
            height: pxToVh(82px, 1080px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            border: 3px solid #fcfffc;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(16px, 20px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
        }
    }
}
