.staff {
    font-family: Cygre;
    display: flex;
    flex-direction: column;

    .button {
        align-self: flex-end;
        margin-top: 30px;
        margin-bottom: 50px;
        cursor: pointer;
        font-style: normal;
        font-family: Cygre;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding-top: 5px;
        color: #ffffff;
        width: 228px;
        height: 32px;
        background: #8adb4a;
        box-shadow:
            0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04),
            0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-left: calc-between-width(1600, 480, 1920, 640);
    }
}

.editButton {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    font-family: Cygre;
    text-align: center;
    padding-top: 5px;
    color: #ffffff;
    width: 128px;
    height: 32px;
    background: #8adb4a;
    box-shadow:
        0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    button {
        cursor: pointer;
        color: #ffffff;
        background: #8adb4a;
        border: none;
    }
}

.css-s0w8ce {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    outline: none;
    .input {
        margin-top: 0;
    }
    .buttonGroup {
        display: flex;
        flex-direction: row;
        align-self: center;
        gap: 10px;

        .resetButton {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #ffffff;
            width: 128px;
            height: 32px;
            background: #42454c;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            opacity: 70%;
        }

        .confirmButton {
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding-top: 5px;
            color: #ffffff;
            width: 128px;
            height: 32px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
            button {
                cursor: pointer;
                color: #ffffff;
                background: #8adb4a;
                border: none;
            }
        }
    }
}
