@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.CheckBox {
    label {
        margin-bottom: pxToVh(20px, 1080px);
        color: #bdc0ce;
        font-family: Cygre;
        @include fluid-type(14px, 18px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .checkbox + label {
        display: inline-flex;
        align-items: center;
        user-select: none;
    }
    .checkbox + label::before {
        content: '';
        display: inline-block;
        width: pxToVw(30px, 1920px);
        height: pxToVh(30px, 1080px);
        flex-shrink: 0;
        flex-grow: 0;
        border: none;
        border-radius: 10px;
        margin-right: pxToVw(20px, 1920px);
        background: #f5f5f5;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 50%;
    }
    .checkbox:checked + label::before {
        background-color: #9be163;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
    }
}
