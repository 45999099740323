@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.MainPage {
    .first-view {
        margin-top: pxToVh(86px, 1080px);
        .title {
            .title-text_main {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(46px, 50px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .title-text_sub {
                color: #949696;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: pxToVw(668px, 1920px);
            }
        }
        .linkBtn {
            cursor: pointer;
            margin-top: pxToVh(48px, 1080px);
            display: flex;
            width: pxToVw(610px, 1920px);
            height: pxToVh(82px, 1080px);
            padding: pxToVh(24px, 1080px) pxToVw(207px, 1920px);
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #8adb4a;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(16px, 20px);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border: none;
        }
    }
    .main-content {
        margin-top: pxToVh(48px, 1080px);

        .title {
            margin-bottom: pxToVh(48px, 1080px);
            .title-text_main {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(46px, 50px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-bottom: pxToVh(15px, 1080px);
                span {
                    padding: pxToVh(6px, 1080px) pxToVw(6px, 1920px)
                        pxToVh(86px, 1080px) pxToVw(6px, 1920px);
                    color: #f5f5f5;
                    background: #8adb4a;
                    font-family: Cygre;
                    @include fluid-type(46px, 50px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            .title-text_sub {
                width: pxToVw(668px, 1920px);
                color: #949696;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .paymentInfo {
            display: flex;
            gap: 20px;
            .paymentInfo-item {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: pxToVw(480px, 1920px);
                height: pxToVh(152px, 1080px);
                padding: pxToVh(24px, 1080px) 0px pxToVh(25px, 1080px)
                    pxToVw(24px, 1920px);
                border-radius: 8px;
                background: #fcfffc;
                .svg {
                    position: absolute;
                    top: pxToVh(20px, 1080px);
                    right: pxToVw(20px, 1920px);
                    cursor: pointer;
                }
                &.pay {
                    display: flex;
                }
            }
        }
        .itemText_main {
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: pxToVh(15px, 1080px);
        }
        .itemText_sub {
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(40px, 44px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .charts {
            margin-top: pxToVh(20px, 1080px);
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: pxToVw(1492px, 1920px);
            height: pxToVh(623px, 1080px);
            border-radius: 8px;
            background: #fcfffc;
            .filters {
                margin-top: pxToVh(24px, 1080px);
                margin-left: pxToVw(50px, 1920px);
                display: flex;
                gap: 20px;
                margin-bottom: pxToVh(24px, 1080px);
                .filter {
                    color: #b9b9b9;
                    font-family: Cygre;
                    @include fluid-type(12px, 16px);
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    padding-bottom: pxToVh(10px, 1080px);
                    white-space: nowrap;
                    cursor: pointer;
                    &.active {
                        color: #0a1614;
                    }
                }
            }
            .cliks {
                margin-left: pxToVw(50px, 1920px);
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(40px, 44px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
}
