@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.range_picker {
    position: relative;
    .react-datepicker__input-container {
        input {
            border: 1px solid black;
            margin-top: pxToVh(20px, 1080px);
            display: flex;
            padding: pxToVh(30px, 1080px) pxToVw(40px, 1920px);
            align-items: center;
            gap: 10px;
            border-radius: 8px;
            background: #f0f0f0;
            outline: none;
            margin-bottom: pxToVh(34px, 1080px);
        }
    }
    .react-datepicker__close-icon::after {
        background: rgba(138, 219, 74, 0.7);
    }
    .react-datepicker-popper {
        z-index: 10;
    }
    .react-datepicker__day--in-range {
        background: rgba(138, 219, 74, 0.7);
    }
    .react-datepicker__day--keyboard-selected {
        background: rgba(138, 219, 74, 1);
    }
    .react-datepicker__day--in-selecting-range:not(
            .react-datepicker__day--in-range,
            .react-datepicker__month-text--in-range,
            .react-datepicker__quarter-text--in-range,
            .react-datepicker__year-text--in-range
        ) {
        background: rgba(138, 219, 74, 0.7);
    }
    .react-datepicker__day:hover {
        background: rgba(138, 219, 74, 1);
    }
    .react-datepicker-popper {
        transform: none !important;
        inset: pxToVh(70px, 1080px) auto auto pxToVw(-18px, 1920px);
    }
    .react-datepicker__triangle {
        display: none;
    }
    .react-datepicker__day-names {
        font-family: Cygre;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .react-datepicker__current-month {
        font-family: Cygre;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}
