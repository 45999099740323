@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.bet-manage {
    .title {
        .title-text_main {
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(46px, 50px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .title-text_sub {
            color: #949696;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: pxToVw(668px, 1920px);
        }
    }
    .linkBtn {
        cursor: pointer;
        white-space: nowrap;
        margin-top: pxToVh(48px, 1080px);
        display: flex;
        width: pxToVw(610px, 1920px);
        height: pxToVh(82px, 1080px);
        padding: pxToVh(24px, 1080px) 207px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #8adb4a;
        color: #fcfffc;
        font-family: Cygre;
        @include fluid-type(16px, 20px);
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border: none;
    }
    .filters {
        display: flex;
        gap: 20px;
        margin-bottom: pxToVh(24px, 1080px);
        .filter {
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: pxToVh(10px, 1080px);
            border-bottom: 1px solid #b9b9b9;
            cursor: pointer;
            &.active {
                color: #0a1614;
                border-bottom: 1px solid #0a1614;
            }
        }
    }
    margin-top: pxToVh(52px, 1080px);
    .btn-wrapper {
        width: pxToVw(1491px, 1920px);
        display: flex;
        justify-content: space-between;
        .category {
            align-self: center;
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding-bottom: pxToVh(10px, 1080px);
            border-bottom: 1px solid #b9b9b9;
            span {
                margin-left: pxToVw(5px, 1920px);
                color: #b9b9b9;
                font-family: Cygre;
                @include fluid-type(14px, 18px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .add-btn {
            display: inline-flex;
            padding: pxToVh(4px, 1080px) pxToVw(12px, 1920px) pxToVh(4px, 1080px) pxToVw(8px, 1920px);
            align-items: center;
            gap: 8px;
            margin-bottom: pxToVh(24px, 1080px);
            border-radius: 3px;
            background: #8adb4a;
            color: #fcfffc;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            line-height: normal;
            border: none;
            white-space: nowrap;
            cursor: pointer;
        }
    }
    .edit {
        margin-left: pxToVw(10px, 1920px);
        position: relative;
        top: pxToVh(6px, 1080px);
        cursor: pointer;
        path{
            fill: #42454c;
        }
    }
}
