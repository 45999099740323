@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.tariff-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: pxToVw(1240px, 1920px);
    height: pxToVh(823px, 1080px);
    border-radius: 8px;
    background: #fcfffc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    outline: none;
    .close {
        position: absolute;
        top: pxToVh(26px, 1080px);
        right: pxToVw(26px, 1920px);
        cursor: pointer;
    }
    .title {
        color: #0a1614;
        font-family: Cygre;
         @include fluid-type(40px, 44px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: pxToVh(14px, 1080px);
        margin-top: pxToVh(33px, 1080px);
        text-align: center;
    }
    .sub-title {
        width: pxToVw(713px, 1920px);
        color: #949696;
        font-family: Cygre;
        @include fluid-type(20px, 24px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: pxToVh(35px, 1080px);
    }
    .card-container {
        display: flex;
        gap: 20px;
        margin: 0 pxToVw(60px, 1920px);
    }
}
