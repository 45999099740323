.admin_layout {
    table {
        border-collapse: collapse;
        font-family: Cygre;
    }

    .MuiTablePagination-select{
        display: none;
    }
    .MuiTablePagination-selectLabel{
        display: none;
    }
    .MuiTablePagination-toolbar{
        display: flex;
        flex-direction: column;
    }
    .css-7ugj6v .MuiTablePagination-toolbar{
        flex-direction: column;
        align-items: center;
    }
    .css-7ugj6v .MuiTablePagination-displayedRows{
        margin-left: 0;
    }
    th {
        padding: 25px 30px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
    }
    thead {
        th {
            background-color: #bcbcbc;
            font-size: 14px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
        }
        th:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
        th:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            .resizer{
                display: none;
            }
        }
        .resizer {
            display: inline-block;
            background: linear-gradient(
                    rgba(255, 255, 255, 0) 23%,
                    #8adb4a 23%,
                    #8adb4a 77%,
                    rgba(255, 255, 255, 0) 77%
                )
                0% 100%;
            width: 3px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            /* prevent scroll on touch devices */
            touch-action: none;
        }

        .isResizing {
            background: #8adb4a;
        }
    }
    tbody {
        display: flex;
        flex-direction: column;
        gap: 10px;
        tr {
            background-color: #ffffff;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            height: 75px;
            border-radius: 3px;
            td {
                //overflow: hidden;
                height: auto;
                padding-top: 20px;
                padding-left: 15px;
                border-right: 2px solid;
                border-image: linear-gradient(
                        rgba(255, 255, 255, 0) 23%,
                        #8adb4a 23%,
                        #8adb4a 77%,
                        rgba(255, 255, 255, 0) 77%
                    )
                    0% 100%;
            }
            td:last-child {
                border: none;
            }
        }
    }
    // td {
    //     overflow: hidden;
    //     white-space: nowrap;
    //     text-overflow: ellipsis;
    //     border: 1px solid black;
    //     padding: 0.5rem 1rem 0.5rem 1rem;
    // }
}
