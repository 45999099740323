@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.add-shop {
    width: 100vw;
    height: 100vh;
    background: #f5f5f5;
    display: flex;
    margin-top: pxToVh(18px, 1080px);

    .form-wrapper {
        display: flex;
        flex-direction: column;
        .title {
            margin-bottom: pxToVh(48px, 1080px);
            .title-mainText {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(46px, 50px);
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .title-subText {
                color: #949696;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            .error-title {
                width: pxToVw(602px, 1920px);
                font-family: Cygre;
                color: red;
                text-align: center;
            }
            .error {
                width: pxToVw(602px, 1920px);
                font-family: Cygre;
                color: red;
            }
            .input {
                width: pxToVw(602px, 1920px);
            }
            .CheckBox {
                width: 100%;
            }
            .linkBtn {
                cursor: pointer;
                display: flex;
                width: pxToVw(602px, 1920px);
                padding: pxToVh(24px, 1080px) 207px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                background: #6c7372;
                color: #fcfffc;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                border: none;
                white-space: nowrap;
                margin-top: pxToVh(60px, 1080px);
                &.active {
                    background: #9be163;
                }
                &.back {
                    margin-top: 5px;
                }
            }
            .helpText {
                margin-top: pxToVh(24px, 1080px);
                width: pxToVw(602px, 1920px);
                text-align: center;
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(20px, 24px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                a {
                    color: #9be163;
                    font-family: Cygre;
                    @include fluid-type(20px, 24px);
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-decoration: none;
                    margin-left: pxToVw(5px, 1920px);
                }
            }
        }
    }
}
