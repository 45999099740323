@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.TextField {
    position: relative;
    label {
        margin-bottom: pxToVh(20px, 1080px);
        color: #bdc0ce;
        font-family: Cygre;
        @include fluid-type(14px, 18px);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .input {
        margin-top: pxToVh(20px, 1080px);
        display: flex;
        padding: pxToVh(30px, 1080px) pxToVw(38px, 1920px);
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #f0f0f0;
        border: none;
        outline: none;
        margin-bottom: pxToVh(34px, 1080px);
        &.error {
            border: 1px solid red;
        }
    }
    .input::placeholder {
        color: #7e8494;
        font-family: Cygre;
        @include fluid-type(14px, 18px);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .errorText {
        white-space: nowrap;
        position: absolute;
        bottom: pxToVh(10px, 1080px);
        left: pxToVw(20px, 1920px);
        color: red;
        font-family: Cygre;
    }
}
