@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.menu {
    width: pxToVw(320px, 1920px);
    height: 100vh;
    position: relative;
    left: -15.5vw;
    transition: left 0.5s ease-in-out;
    border-radius: 0px 20px 20px 0px;
    background: #0a1614;
    &.open {
        left: 0;
        .toggle {
            transform: rotate(0deg);
        }
    }
    .logo {
        width: pxToVw(190px, 1920px);
        height: pxToVh(59px, 1080px);
        margin-left: pxToVw(60px, 1920px);
        margin-top: pxToVh(73px, 1080px);
        margin-bottom: pxToVh(80px, 1080px);
    }
    .toggle {
        position: absolute;
        left: pxToVw(307px, 1920px);
        top: pxToVh(90px, 1080px);
        width: pxToVw(27px, 1920px);
        height: pxToVh(27px, 1080px);
        background: white;
        border-radius: 50px;
        transform: rotate(180deg);
        cursor: pointer;
        .toggle-svg {
            width: pxToVw(28px, 1920px);
            height: pxToVh(28px, 1080px);
            position: relative;
            left: pxToVw(-1px, 1920px);
        }
    }
    .link-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: pxToVh(40px, 1080px);
        margin-left: pxToVw(60px, 1920px);
        a {
            display: inline-flex;
            text-decoration: none;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .link-logo {
                fill: #dcdcdc;
                width: pxToVw(24px, 1920px);
                height: pxToVh(24px, 1080px);
                margin-right: pxToVw(14px, 1920px);
                color: #b9b9b9;
            }
            &.active {
                color: #8adb4a;
                .link-logo {
                    path {
                        fill: #8adb4a;
                    }
                }
            }
        }
    }
    .btn-container {
        display: flex;
        flex-direction: column;
        margin-top: pxToVh(170px, 1080px);
        margin-left: pxToVw(60px, 1920px);
        .support {
            margin-bottom: pxToVw(40px, 1080px);
            display: inline-flex;
            text-decoration: none;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            .logo-support {
                fill: #dcdcdc;
                width: pxToVw(24px, 1920px);
                height: pxToVh(24px, 1080px);
                margin-right: pxToVw(14px, 1920px);
                color: #b9b9b9;
            }
            &.active {
                color: #8adb4a;
                .logo-support {
                    path {
                        fill: #8adb4a;
                    }
                }
            }
        }
        .logOut {
            display: inline-flex;
            text-decoration: none;
            color: #b9b9b9;
            font-family: Cygre;
            @include fluid-type(14px, 18px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            cursor: pointer;
            .logo-logOut {
                width: pxToVw(26px, 1920px);
                height: pxToVh(26px, 1080px);
                margin-right: pxToVw(14px, 1920px);
            }
        }
    }
}
