.Mui-expanded {
    .MuiTreeItem-label {
        text-align: start;
    }
}
.MuiTreeItem-root {
    .css-1g86id8-MuiTreeItem-content {
        &.Mui-selected {
            background-color: #f0f0f0;
            &.Mui-focused {
                background-color: #f0f0f0;
            }
        }
    }
    .MuiTreeItem-label {
        .Mui-checked {
            color: #8adb4a;
        }
    }
}

.tree-wrapper{
    max-height: 320px;
    overflow-y: auto;
    overflow-x: hidden;
}

.react-checkbox-tree {
    ol {
        padding-left: 45px;
        font-size: 18px;
        font-family: Cygre;
        ol{
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }
    .rct-collapse {
        &.rct-collapse-btn {
            cursor: pointer;
            background: #fcfffc;
            border: none;
            width: 20px;
            height: 35px;
            background-image: url('../../assets/arrow.svg');
            background-repeat: no-repeat;
        }
    }
}
.add-shop{
    .react-checkbox-tree {
        .rct-collapse {
            &.rct-collapse-btn {
                cursor: pointer;
                background: #f5f5f5;
                border: none;
                width: 20px;
                height: 35px;
                background-image: url('../../assets/arrow.svg');
                background-repeat: no-repeat;
            }
        }
    }
}
