@mixin fluid-type(
    $min-font-size: 12px,
    $max-font-size: 21px,
    $lower-range: 1536px,
    $upper-range: 1920px
) {
    font-size: calc(
        #{$min-font-size} + #{(
                ($max-font-size / ($max-font-size * 0 + 1)) -
                    ($min-font-size / ($min-font-size * 0 + 1))
            )} * ((100vw - #{$lower-range}) / #{(
                        ($upper-range / ($upper-range * 0 + 1)) -
                            ($lower-range / ($lower-range * 0 + 1))
                    )})
    );

    @media screen and (max-width: $lower-range) {
        font-size: $min-font-size;
    }
    @media screen and (min-width: $upper-range) {
        font-size: $max-font-size;
    }
}
@function pxToVw($px, $viewport) {
    @return calc($px * 100vw / $viewport);
}
@function pxToVh($px, $viewport) {
    @return calc($px * 100vh / $viewport);
}
.Support {
    margin-top: pxToVh(86px, 1080px);
    .title {
        .title-text_main {
            color: #0a1614;
            font-family: Cygre;
            @include fluid-type(46px, 50px);
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .title-text_sub {
            color: #949696;
            font-family: Cygre;
            @include fluid-type(20px, 24px);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: pxToVw(668px, 1920px);
        }
    }
    .info {
        display: flex;
        gap: 87px;
        margin-bottom: pxToVh(30px, 1080px);
        margin-top: pxToVh(48px, 1080px);
        .info-item {
            display: flex;
            gap: 30px;
            span {
                color: #0a1614;
                font-family: Cygre;
                @include fluid-type(24px, 28px);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
    .working-mode {
        display: flex;
        flex-direction: column;
        span {
            color: #949696;
            font-family: Cygre;
            @include fluid-type(12px, 16px);
            font-style: normal;
            line-height: normal;
        }
    }
}
