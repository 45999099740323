.css-79ws1d-MuiModal-root {
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
        color: #8adb4a;
    }
}
.logs {
    .SortIcon {
        position: absolute;
    }
    .btn {
        align-self: center;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        width: 100px;
        height: 28px;
        background: #8adb4a;
        box-shadow:
            0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04),
            0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
    }
    .toggle {
        margin: 40px 0;
        display: flex;
        gap: 20px;
        //margin-bottom: pxToVh(24px, 1080px);
        .filter {
            color: #b9b9b9;
            font-family: Cygre;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            // padding-bottom: pxToVh(10px, 1080px);
            border-bottom: 1px solid #b9b9b9;
            cursor: pointer;
            &.active {
                color: #0a1614;
                border-bottom: 1px solid #0a1614;
            }
        }
    }
    td:has(.btn) {
        padding-top: 12px;
    }

    .filters {
        margin-top: 0;
        display: flex;
        gap: 20px;
        .input {
            border: 1px solid black;
        }
        .reset {
            align-self: center;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            height: 28px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
        }
    }
}
.events {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 700px;
    border: 2px solid #000;
    background: #ffffff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .items {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
    }
    .save{
        align-self: center;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            width: 100px;
            height: 28px;
            background: #8adb4a;
            box-shadow:
                0px 10px 20px rgba(0, 0, 0, 0.04),
                0px 2px 6px rgba(0, 0, 0, 0.04),
                0px 0px 1px rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            border: none;
    }
    .reset{
        align-self: center;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #ffffff;
        width: 100px;
        height: 28px;
        background: #6c7372;
        box-shadow:
            0px 10px 20px rgba(0, 0, 0, 0.04),
            0px 2px 6px rgba(0, 0, 0, 0.04),
            0px 0px 1px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        border: none;
    }
}
